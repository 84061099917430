
<template>
  <div class="main-content">


    <loading :active.sync="isLoading"
             :is-full-page="fullPage"></loading>

    <b-table striped hover :items="segments" :fields="columns">
      <template v-slot:cell(customer)="data">
        <span v-if="data.item.customer">{{ data.item.customer.name }} </span>
      </template>

      <template v-slot:cell(id)="data">
        <span v-if="data.item.customer">{{ data.item.customer.mobileNumber }} </span>
      </template>

      <template v-slot:cell(customerId)="data">
        <span v-if="data.item.customer">{{ data.item.customer.email }} </span>
      </template>

    </b-table>

    <div class="row">
      <div class="col-md-12">
        <nav aria-label="Page navigation float-right" style="margin-top: 20px;">
          <ul class="pagination justify-content-center">
            <li v-if="pagination.current_page > 1" class="page-item"><a @click.prevent="changePage(pagination.current_page - 1)" class="page-link" href="#">Previous</a></li>
            <li v-for="page in pagesNumber"
                v-bind:class="[ page == isActived ? 'active' : '']" class="page-item"><a @click.prevent="changePage(page - 1)" class="page-link" href="#">{{ page }}</a></li>
            <li v-if="pagination.current_page < pagination.last_page"  class="page-item"><a  @click.prevent="changePage(pagination.current_page + 1)"class="page-link" href="#">Next</a></li>
          </ul>
        </nav>
      </div>
    </div>

    <b-modal size="lg" id="view-redemptions" title="Redemptions" @cancel="true"   centered>
      <table class="table table-bordered">
        <tr>
          <th>Date</th>
          <th>Amount</th>
          <th>Outlet</th>
          <th>Cashier</th>
          <th>Netsuite Status</th>
        </tr>
        <tr v-for="redemption in redemptions">
          <td >{{redemption.created}}</td>
          <td >{{redemption.amount}}</td>
          <td >
            <span v-if="redemption.outlet">{{redemption.outlet.name}}</span>
          </td>
          <td >
            <span v-if="redemption.redeemedBy">{{redemption.redeemedBy.name}} </span>
          </td>
          <td >{{redemption.netsuiteVoucherPost}}</td>
        </tr>
      </table>
    </b-modal>


  </div>
</template>
<script>

  import Loading from 'vue-loading-overlay';
  // Import stylesheet
  import 'vue-loading-overlay/dist/vue-loading.css'

  import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      segments:[],
      roles:[],
      columns:["customer",
        {
        key: 'id',
        label: 'Phone Number',
        sortable: false
      },{
        key: 'customerId',
        label: 'Email Address',
        sortable: false
      }],
      isLoading: false,
      fullPage: true,
      keyword:"",
      pagination: {
        total: 0,
        per_page: 2,
        from: 1,
        to: 0,
        current_page: 0
      },
      offset: 4,
    };
  },
  computed: {
    isActived: function () {
      return this.pagination.current_page + 1;
    },
    pagesNumber: function () {
      if (!this.pagination.to) {
        return [];
      }
      var from = this.pagination.current_page - this.offset;
      if (from < 1) {
        from = 1;
      }
      var to = from + (this.offset * 2);
      if (to >= this.pagination.last_page) {
        to = this.pagination.last_page;
      }
      var pagesArray = [];
      while (from <= to) {
        pagesArray.push(from);
        from++;
      }
      return pagesArray;
    }
  },
  components: {
    Loading
  },
  mounted (){
    this.getSegmentCustomersData();
  },

  methods: {
    ...mapActions(["getSegmentCustomers"]),
    changePage: function (page) {
      this.pagination.current_page = page;
      this.getSegmentCustomersData();
    },
    getSegmentCustomersData(){
      let self = this;
      this.getSegmentCustomers({page: this.pagination.current_page, id: this.$route.params.id})
        .then(function (segments){

          self.segments = segments.content;
          self.pagination.current_page = segments.number;
          self.pagination.total = segments.totalElements;
          self.pagination.per_page = segments.numberOfElements;
          self.pagination.from = segments.pageable.offset + 1 ;
          self.pagination.to = segments.pageable.pageSize;
          self.pagination.last_page = segments.totalPages;

        })
        .catch(function (error) {

          if( typeof error.response.status != "undefined"){
            if(error.response.status == 401){
              location.replace("/login");
            }

          }
        })
    }
  }
};
</script>
